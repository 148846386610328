import { Injectable } from '@angular/core';
import {User} from '../models/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {BaseAuthService} from './base-auth.service';
import {Router} from "@angular/router";
import {Seller} from '../models/seller';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NotificationService} from './notification.service ';
import {Observable} from 'rxjs';
import {Location} from '@angular/common';
import { runInThisContext } from 'vm';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  key = "p2pex_user_token";
  isAuth = false;
  public managerNumber = "+237695164368";

  user: User = null;
  baseAddress: string;
  ListMarker:Array<any>;
  // user: User = new User();

  constructor(
      public httpClient: HttpClient,
      public baseAuth: BaseAuthService,
      public router: Router,
      private notifyService : NotificationService,
      private ngxService: NgxUiLoaderService,
      private location: Location
  ) {
    this.baseAddress = baseAuth.baseAddress
  }


  // ----------------------------  POST REQUEST  -----------------------------
  // ----------------------------  POST REQUEST  -----------------------------

 

  public getUserByEmailPassword(email: string, password: string){
    let data = {
      username: email,
      password: password
    };
    return this.httpClient.post<User>(`https://telefret.com/api_redirect/`, data, this.baseAuth.baseOptions);
  }

  public getUserMapMarks(id=null){
    let token =JSON.parse(this.getItem()) as User ;
    let header = new HttpHeaders();
    header.append("Content-Type", "application/json");
    let baseOptions = {headers:     header.append('Authorization', token.token)
  }; return this.httpClient.get(`https://telefret.com/api_redirect/?id=${id?id:''}&token=${this.user.token}`, baseOptions);
  }

  public postUser(user: User){
    return this.httpClient.post<User>(`${this.baseAddress}?f=postUser`,  user, this.baseAuth.baseOptions);
  }


  // // ----------------------------  PUT REQUEST  -----------------------------
  // // ----------------------------  PUT REQUEST  -----------------------------

  // public putUser(user: User){
  //   user.Id = +user.Id;
  //   let data = {
  //     User: JSON.stringify(user)
  //   };
  //   return this.httpClient.post<User>(`${this.baseAddress}?f=putUser&id=${user.Id}`, data, this.baseAuth.baseOptions);
  // }


  //  ---------------------------- section token ------------------------------------------------

  logOut() {
    this.deleteItem();
    this.user = null;
    this.isAuth = false;
  }

  addItem(item: string, key = this.key) {
    localStorage.setItem(key, item);
  }

  getItem(key = this.key) {
    return localStorage.getItem(key)
  }

  deleteItem(key = this.key) {
    localStorage.removeItem(key);
  }
manager;
  raw(qrInfo, res?): Observable<string> {
    /** Returns the freshly created Observable **/
    return Observable.create(function(observer) {
      /** Call your method with the callback **/
      this.manager.getData(res.width, (res1) => {
        /** Emit the callback response to the Observable **/
        // observer.next(of<string>res1)
        /** Complete the Observable to close it **/
        // observer.complete();
      });
    });
  }

  checkIfUserExist(){
        let token =JSON.parse(this.getItem()) ;
        // if (token) {
        
        //     this.getUserMapMarks().subscribe(
        //       async value => {
        //         console.log(value);
        //         this.user = token;
        //         this.ListMarker=value as any;
        //         this.notifyService.showSuccess("Bienvenue!", "TELEFRET")

        //       },
        //       error1 => {
        //         this.notifyService.showError("Veillez vous connecté!", "TELEFRET")
        //         this.location.go("");

        //         console.log(error1);
        //       }
        //     );
         
        // }else{
          // this.getUserByEmailPassword("socopat","socopat2019").subscribe(
          //   value => {
          //     console.log(value);
          //     this.ngxService.stop();
    
             
    
          //     if(value.hasOwnProperty('id')){
          //       this.addItem(JSON.stringify(value) );
          //       this.user = value;
          //       this.isAuth = true;
          //         // this.router.navigateByUrl('/user');
          //       this.notifyService.showSuccess("Bienvenue!", "TELEFRET")
          //     }
          //     else {
          //       this.notifyService.showError("Email ou mot de passe incorrect!", "TELEFRET");
          //       return;
          //     }
          //   },
          //   error => {
          //     console.log(error);
          //     this.ngxService.stop();
          //     this.notifyService.showError("Email ou mot de passe incorrect!", "TELEFRET")
          //   }
          // )

        }
  // }
}
